<template>
    <v-card>
        <v-card-title
          primary-title
          class="d-flex justify-space-between blueGrayMinsal--text"
        >
          Role
          <div>
            <v-btn
            v-if="permission"
              class="ma-1"
              @click="$router.push('/role/create')"
              ><v-icon left>mdi-plus</v-icon>Add</v-btn
            >
          </div>
        </v-card-title>
        <v-card-text>
          <v-skeleton-loader v-if="loading == true"></v-skeleton-loader>
          <v-data-table
            :headers="headers"
            :items="roles"
            item-key="id"
            class="elevation-0 border-1"
            no-data-text="No hay datos"
            no-results-text="No hay resultados"
            :footer-props="{
              'items-per-page-options': [5, 10, 20],
              'items-per-page-text': 'Rows',
              'page-text': '',
            }"
            v-if="roles.length > 0 && loading == false && permission === true"
            :key="dataTableKey"
            mobile-breakpoint="0"
          >
            <template v-slot:[`item.accion`]="{ item }">
                <v-btn icon small @click="setDeleted(item)"
                ><v-icon>mdi-trash-can</v-icon></v-btn
              >
              <v-btn icon small @click="editRole(item)"
                ><v-icon>mdi-pencil</v-icon></v-btn
              >
            </template>
          </v-data-table>
          <v-alert
            icon="mdi-information"
            prominent
            text
            v-else
          >
            No data available</v-alert
          >
          <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          Delete role {{selectedDelete.name}}?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="deleteRole(selectedDelete)"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from '@axios'
import VueAlertify from 'vue-alertify'

export default {
  data() {
    return {
      roles: [],
      selectedDelete: [],
      dataTableKey: 0,
      isLoaded: false,
      loading: false,
      dialog: false,
      permission: false,
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'name',
        },
        { text: 'Action', value: 'accion', sortable: false, width: '100' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      hasUserRole: 'auth/hasUserRole',
      hasUserPermission: 'auth/hasUserPermission',
    }),
  },
  methods: {
    setDeleted(role) {
      this.dialog = true
      this.selectedDelete = role
    },
    getRoles() {
      if (this.hasUserRole('Super Admin') || this.hasUserRole('Client Owner')) {
        axios
          .get('role')
          .then(response => {
            this.roles = response.data
            this.loading = false
            this.permission = true
            if (this.roles.length === 0) {
            }
          })
          .catch(() => {
            this.$alertify.error('Unable to get roles')
          })
      } else {
        this.permission = false
        this.loading = false
      }
    },
    deleteRole(rol) {
      axios
        .post('/role/delete', { id: rol.id })
        .then(response => {
          this.$alertify.success('Rol deleted')
          this.dialog = false
          this.getRoles()
        })
        .catch(error => {
          // TODO: Next Update - Show notification
          console.error('Oops, Unable to delete!')
          this.$alertify.error('Unable to delete')
        })
    },
    editRole(item) {
      localStorage.setItem('rolEdit', JSON.stringify(item))
      this.$router.push('role/edit')
    },
  },
  async mounted() {
    if (this.hasUserRole('Super Admin') || this.hasUserRole('Client Owner')) {
      await this.getRoles()
    } else {
      this.loading = false
      this.$router.push({ name: 'misc-not-authorized' })
    }

    this.dataTableKey += 1
  },
}
</script>
